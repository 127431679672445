<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>{{
      isAddingChip ? 'Agregar chips' : 'Configurar chips'
    }}</template>
    <div class="row mb-4" v-if="!chipSelected">
      <div>
        <div class="d-flex mb-3 justify-content-between" v-if="!isAddingChip">
          <h5>Chips almacenadas</h5>
          <div class="col-4">
            <div class="d-flex align-items-center" v-if="gameCurrency">
              <label class="me-2">Filtrar: </label>
              <multiselect
                v-model="filterCurrency.currency"
                :options="formatOptions(gameCurrency.currencies)"
                @input="handleFilter"
                label="name"
                placeholder="Selecciona una moneda"
                select-label="Click para filtrar"
              >
              </multiselect>
            </div>
          </div>
        </div>
        <div v-if="!chips.length">No hay chips</div>
        <div class="row" v-if="chips.length">
          <div
            v-for="(chip, index) in chips"
            :key="index"
            class="col-sm-12 col-md-5 card mx-auto"
            :style="chip.active ? 'opacity: 1' : 'opacity: 0.8'"
          >
            <span class="badge bg-secondary badge-status rounded-pill">{{
              chip.active ? 'Activa' : 'Inactiva'
            }}</span>

            <div
              class="d-flex align-items-center"
              @click="() => selectChip(chip)"
            >
              <Chip :chip="chip" />
              <div class="chip-info">
                <span><strong>Numero:</strong> {{ chip.number }}</span>
                <span
                  ><strong>Valor:</strong> {{ chip.value }}
                  {{ chip.currency.short }}</span
                >
                <span
                  ><strong>Color principal:</strong>
                  {{ chip.color.primary }}</span
                >
                <span
                  ><strong>Color secundario:</strong>
                  {{ chip.color.secondary }}</span
                >
              </div>
            </div>
            <span class="badge bg-primary rounded-pill"
              >{{ $t('helpers.select') }} para editar</span
            >
            <span
              class="badge delete-chip bg-danger rounded-pill"
              @click="deleteChip(chip)"
              v-if="!isAddingChip"
            >
              <span class="uil-trash-alt me-1"></span>
              Click aquí para eliminar
            </span>
          </div>
        </div>
        <div
          class="d-flex justify-content-between align-items-center"
          v-if="!isAddingChip"
        >
          <p class="btn btn-primary btn-plus" @click="addAnotherChip">
            +
            <span class="badge bg-secondary rounded-pill">Agregar más</span>
          </p>
          <div class="pagination">
            <b-button
              class="uil-arrow-left"
              variant="primary"
              @click="handlePrev"
              :disabled="chipPage === 1"
            ></b-button>
            <b-button
              class="uil-arrow-right"
              variant="primary"
              @click="handleNext"
              :disabled="chips.length < 6"
            ></b-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="chipSelected">
      <p>
        <span class="btn btn-secondary me-3" @click="handleBack">&larrhk;</span
        >Regresar
      </p>
      <div
        class="d-flex justify-content-end ms-auto w-25 flex-column"
        v-if="gameCurrency"
      >
        <label>Selecciona una moneda: </label>
        <multiselect
          v-model="typeform.currency"
          :options="formatOptions(gameCurrency.currencies)"
          label="name"
          placeholder="Selecciona una moneda"
        ></multiselect>
        <div v-if="$v.typeform.currency.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.currency.required"
            >Este campo es requerido</span
          >
        </div>
      </div>

      <div
        class="d-flex justify-content-center"
        v-if="Object.entries(typeform).length"
      >
        <Chip :chip="typeform" />
      </div>
      <form>
        <b-row class="mb-3 mx-auto">
          <b-col sm="12" md="6" class="mx-auto">
            <div class="mb-3">
              <label>FICHA</label>
              <input
                v-model="typeform.number"
                type="text"
                :class="`form-control ${
                  $v.typeform.number.$error ? 'is-invalid' : ''
                }`"
                placeholder="1000"
                name="providerId"
                maxlength="7"
              />
              <div v-if="$v.typeform.number.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.number.maxLength">
                  7 caracteres maximos</span
                >
                <span v-if="!$v.typeform.number.numeric" class="d-block">
                  Solo valores numericos</span
                >
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6" class="mx-auto">
            <div class="mb-3">
              <label
                >VALOR ({{
                  typeform.currency ? typeform.currency.name : 'MONEDA'
                }})</label
              >
              <input
                v-model="typeform.value"
                type="text"
                class="form-control"
                placeholder="1.5"
                name="providerId"
                maxlength="7"
                :class="`form-control ${
                  $v.typeform.value.$error ? 'is-invalid' : ''
                }`"
              />
              <div v-if="$v.typeform.value.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.value.maxLength">
                  7 caracteres maximos</span
                >
                <span v-if="!$v.typeform.value.numeric" class="d-block">
                  Solo valores numericos</span
                >
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-3 mx-auto">
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>COLOR DE FICHA (PRIMARIO)</label>
              <input
                v-model="typeform.color.primary"
                type="color"
                class="form-control"
                placeholder="HEX"
                name="providerId"
              />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>COLOR DE FICHA (SECUNADARIO)</label>
              <input
                v-model="typeform.color.secondary"
                type="color"
                class="form-control"
                placeholder="HEX"
                name="providerId"
              />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>FICHA ACTIVA</label>
              <input
                id="chipIsActive"
                type="checkbox"
                :checked="typeform.active"
                class="form-check-input ms-2"
                v-model="typeform.active"
              />
            </div>
          </b-col>
        </b-row>

        <div class="mb-3 mx-auto">
          <div class="d-flex justify-content-center">
            <vs-button @click.prevent="save()" v-if="isAddingChip">
              Agregar
            </vs-button>
            <vs-button @click.prevent="save()" v-if="!isAddingChip">
              Guardar
            </vs-button>
            <vs-button @click="closeModa()" type="button" success>
              Cancelar
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { maxLength, numeric, required } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import { mapActions } from 'vuex';
import { Chip } from '../../../../../components';

export default {
  props: [
    'gameCurrency',
    'chips',
    'isAddingChip',
    'chipPage',
    'filterCurrency',
  ],
  components: { Multiselect, Chip },
  data() {
    return {
      chipSelected: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {
        currency: null,
      },
      selectionOpen: false,
      chipSelectedDetail: {},
    };
  },
  validations: {
    typeform: {
      number: {
        numeric,
        maxLength: maxLength(7),
      },
      currency: {
        required,
      },
      value: {
        numeric,
        maxLength: maxLength(7),
      },
    },
  },
  methods: {
    handleNext() {
      this.$emit('nextPageChip');
    },
    handlePrev() {
      this.$emit('prevPageChip');
    },
    handleFilter() {
      this.$emit('handleSelection');
    },
    formatOptions(currencies) {
      if (!currencies) return;

      const shorts = currencies.map(({ currency }) => {
        return { name: currency.short, _id: currency._id };
      });
      return shorts;
    },
    async deleteChip(chip) {
      const { isConfirmed } = await Swal.fire({
        title: '¿Está seguro en eliminar esta ficha?',
        showCancelButton: true,
        cancelButtonText: 'NO',
        confirmButtonText: 'SI',
        icon: 'warning',
      });
      if (!isConfirmed) return;

      const { data } = await this.$http.delete(
        `dragon-tiger/${chip._id}/delete-chip`
      );

      if (!data.ok) {
        return Swal.fire({
          title: 'Error eliminando ficha',
          timer: 1000,
        });
      }

      this.closeModa();
      return Swal.fire({
        title: 'Ficha eliminada',
        timer: 1000,
        showCancelButton: false,
      });
    },
    nFormatter(num, digits) {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value;
        });
      return item
        ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : '0';
    },
    addAnotherChip() {
      this.$emit('addAnotherChip');
    },
    handleBack() {
      this.chipSelected = false;
      this.chipSelectedDetail = {};
      this.typeform = {};
      this.$emit('handleGoBack');
    },
    async save() {
      this.typesubmit = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return console.log('ERROR');
      }

      if (this.isAddingChip) {
        const dragonTiger = this.gameCurrency.currencies[0].dragonTiger;

        const form = { ...this.typeform, currency: this.typeform.currency._id };

        const data = await this.addChip({
          path: 'dragon-tiger',
          id: dragonTiger,
          form,
        });

        if (!data.ok) return Swal.fire(data.msg, '', 'error');

        Swal.fire('Chip almacenada', '', 'success');
      } else {
        const data = await this.updateChip({
          path: 'dragon-tiger',
          chip: this.typeform._id,
          form: { ...this.typeform, currency: this.typeform.currency._id },
        });

        if (!data.ok) return Swal.fire('Error actualizando chip', '', 'error');

        Swal.fire('Chip actualizada', '', 'success');
        return this.handleBack();
      }
    },
    closeModa() {
      this.typeform = {};
      this.chipSelected = false;
      this.modal = false;
      this.chipSelectedDetail = {};
      this.$emit('closeModa');
    },

    selectChip(chip) {
      this.chipSelectedDetail = chip;
      this.typeform = {
        number: chip.number,
        value: chip.value,
        color: chip.color,
        active: chip.active,
        ...chip,
      };
      this.chipSelected = true;
    },
    ...mapActions({
      addChip: 'games/addChips',
      updateChip: 'chips/updateChip',
    }),
  },
};
</script>

<style scoped>
.pagination > * {
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  margin-left: 5px;
  cursor: pointer;
  color: #fff;
}

.delete-chip {
  bottom: 0;
}

.badge {
  position: absolute;
  right: -10px;
  display: none;
}

.badge-status {
  display: block;
  left: 0;
  top: 0;
  right: inherit;
}

.card:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.274);
  cursor: pointer;
}

.card:hover .badge {
  display: block;
}

.btn-plus {
  position: relative;
}

.btn-plus .badge {
  right: inherit;
}

.btn-plus:hover .badge {
  display: block;
}

.fish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 30px;
  border-radius: 50%;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.569),
    0 0 5px 1px rgba(90, 90, 90, 0.66),
    inset 0 35px 1px rgba(255, 255, 255, 0.334);
  -moz-box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.576),
    0 0 5px 1px rgba(90, 90, 90, 0.392),
    inset 0 35px 1px rgba(255, 255, 255, 0.397);
  -webkit-box-shadow:
    inset 0 0 1px 1px rgba(0, 0, 0, 0.1),
    0 0 5px 1px rgba(90, 90, 90, 0.595),
    inset 0 35px 1px rgba(255, 255, 255, 0.2);
  border-width: 8px;
  border-style: dashed;
}

.fish span {
  font-family: Verdana;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bolder;
}

.chip-info {
  display: flex;
  flex-direction: column;
}
</style>

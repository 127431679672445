<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos de la mesa"
      ref="pageForm"
      :items="items"
      :isAdmin="isItForAdmin"
    >
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <game-menu
      ref="gameMenu"
      :items="gameMenuItems"
      @handleDirection="handleDirection"
    ></game-menu>
    <AddCurrency
      ref="addCurrency"
      :tigerDragon="tigerDragonSelected"
      :currencies="currencies"
    />
    <ConfigChips
      ref="configChips"
      :chips="chips"
      :isAddingChip="isAddingChip"
      :gameCurrency="gameCurrencies"
      :chipPage="chipPage"
      :filterCurrency="filterCurrency"
      @addAnotherChip="addAnotherChip"
      @handleGoBack="handleGoBack"
      @handleSelection="handleSelection"
      @nextPageChip="nextPageChip"
      @prevPageChip="prevPageChip"
    />
    <ConfigLimits
      ref="configLimits"
      :currencies="dtCurrencies"
      :currWithOutLimits="currWithOutLimits"
      :limitsByCurrencies="limitsByCurrencies"
      :isUpdating="updatingLimits"
      :noLimitsCurrencies="noLimitsCurrencies"
      :gameId="gameId"
    />
    <ManualResult :game="gameSelected" ref="manualResult" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- <div>
              <router-link to="tigerDragonEmulator">Ir al emulador</router-link>
            </div> -->
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(logo)="data">
                  <b-img
                    :src="data.item.logo ? data.item.logo : 'NOIMATOCHARGE'"
                    height="80px"
                    class="logo-img"
                    @error="handleError"
                  ></b-img>
                </template>
                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="position-absolute"
                    >
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <div v-if="isItForAdmin">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">Editar</span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin">
                        <b-dropdown-item @click="remove(data.item.uuid)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">Eliminar</span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin">
                        <b-dropdown-item @click="resultManual(data.item)">
                          <i class="uil-focus-target m-2 font-size-18" />
                          <span class="align-middle ml-5">Ronda manual</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="goTablero(data.item)">
                          <i
                            class="mdi mdi-google-controller m-2 font-size-18"
                          />
                          <span class="align-middle ml-5">Jugar</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main';
import { GameMenu, PageHeader } from '@/components';
import {
  PageForm,
  AddCurrency,
  ConfigChips,
  ConfigLimits,
  ManualResult,
} from './components';
import { mapActions, mapGetters } from 'vuex';
import { breadCrumbData } from '@/helpers/breadcrumbs/breadCrumbs';
import { filterMenu } from '@/helpers/filterMenu';
import Swal from 'sweetalert2';

export default {
  components: {
    GameMenu,
    Layout,
    PageHeader,
    PageForm,
    AddCurrency,
    ConfigChips,
    ConfigLimits,
    ManualResult,
  },
  data() {
    return {
      title: 'Tigre Dragón',
      items: breadCrumbData.tigerDragon,
      gameMenuItems: [],
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      typeform: {
        name: '',
        nameOfDragon: 'Dragon',
        nameOfTiger: 'Tiger',
        chanceSimple: 0,
        equalitySimple: 0,
        equalityPerfect: 0,
        goldenK: 0,
        jackpot: 0,
      },
      userData: '',
      userRole: '',
      gameFisic: [],
      tableData: [],
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Nombre',
        },
        {
          key: 'logo',
          label: 'Logo',
        },
        {
          key: 'status',
          label: this.$t('tableReports.status'),
        },
        {
          key: 'actions',
          label: 'Acciones',
        },
      ],
      currentPage: 1,
      sortBy: 'desc',
      sortDesc: true,
      filterOn: ['name'],
      tigerDragonSelected: {},
      gameSelected: {},
      currencies: [],
      chips: [],
      isAddingChip: false,
      gameCurrencies: {},
      filterCurrency: {},
      filterChip: {},
      chipPage: 1,
      gameId: '',
      updatingLimits: false,
      dtCurrencies: [],
      limitsByCurrencies: [],
      currWithOutLimits: [],
      noLimitsCurrencies: [],
    };
  },
  computed: {
    ...mapGetters({
      getCurrencies: 'currencies/getCurrencies',
      getGameCurrencies: 'games/getCurrencies',
      getGames: 'dragonTiger/getGames',
      success: 'dragonTiger/isSuccess',
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    async getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.userData.role ? this.userData.role.name : '';

      try {
        await this.fetchGames();
        this.tableData = this.getGames;
      } catch (error) {
        console.log('error', error);
      }
    },
    getData() {
      this.getUserRole();
      // this.fetchCurrencies()
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: '',
        nameOfDragon: 'Dragon',
        nameOfTiger: 'Tiger',
        chanceSimple: 0,
        equalitySimple: 0,
        equalityPerfect: 0,
        goldenK: 0,
        jackpot: 0,
      };

      this.getData();
    },
    handleOpen() {
      this.$refs.gameMenu.modal = true;
      const items = filterMenu(this.$route.meta.parent)[0].subItems;
      this.gameMenuItems = items;
    },
    handleDirection(to) {
      if (this.$route.path === to.link) {
        return (this.$refs.gameMenu.modal = false);
      }

      return this.$router.push(to.link);
    },
    async handleError(e) {
      const image = await import('@/assets/images/users/pngegg.png');
      e.target.src = image.default;
    },
    goTablero(item) {
      this.$router.push({
        name: 'tigerDragonEmulator',
        params: {
          id: item._id,
          uuid: item.uuid,
        },
      });
    },
    resultManual(game) {
      this.gameSelected = game;
      this.$refs.manualResult.modal = true;
    },
    edit(item) {
      // this.$http
      //   .get(`/roulettes/${item._id}/currencies`)
      //   .then(({ data }) => {
      this.typeform = { ...item };
      //     this.typeform = { ...item, currencies: data.currenciesResults };
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      this.$refs.pageForm.modal = true;
    },
    async remove(id) {
      const isConfirmed = await this.handleDeleteSwal(
        '¿Estás seguro de eliminar este juego?'
      );

      if (!isConfirmed) return;

      try {
        await this.deleteGame(id);

        if (!this.success)
          return this.handleAlertSwal('error', 'Error borrando el juego');

        this.handleAlertSwal('success', 'Juego eliminado con exito');
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    async addCurrency(item) {
      await this.fetchGameCurrencies({ path: 'dragon-tiger', id: item._id });
      const gameCurrencies = this.getGameCurrencies;

      this.tigerDragonSelected = { ...item, currencies: gameCurrencies };

      this.currencies = this.getCurrencies;

      this.$refs.addCurrency.modal = true;
    },
    async configChips(item) {
      this.filterCurrency = {};
      this.chips = [];

      if (item) {
        this.gameId = item._id;
      }

      try {
        const data = await this.fetchGameCurrencies({
          path: 'dragon-tiger',
          id: this.gameId,
        });

        if (!data.ok) {
          return Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'Debe agregar una moneda antes de configurar las fichas  ',
            showConfirmButton: false,
            timer: 1500,
          });
        }

        this.gameCurrencies = data;

        const filter = {
          page: this.chipPage,
          currency: this.filterChip.currencyId,
        };

        const dataChips = await this.findChips({
          path: 'dragon-tiger',
          id: this.gameId,
          filter,
        });

        if (!dataChips.ok) {
          this.isAddingChip = false;
          this.$refs.configChips.modal = true;
          return (this.chips = []);
        }

        if (dataChips.chips.length) {
          this.isAddingChip = false;
          this.chips = dataChips.chips.map((chip) => {
            return { ...chip, currency: chip.dragTigerCurrency[0] };
          });

          return (this.$refs.configChips.modal = true);
        }
      } catch (error) {
        console.log('ERROR CONFIG CHIPS TIGER DRAGON', error);
      }
    },
    async addAnotherChip() {
      const chips = await this.fetchDefaultChips();
      if (!chips) return Swal.fire('Error al obtener las fichas', '', 'error');

      this.chips = chips;
      this.isAddingChip = true;
      this.$refs.configChips.modal = true;
    },
    async configLimits(value) {
      this.updatingLimits = false;
      this.dtCurrencies = [];
      this.noLimitsCurrencies = [];
      this.currWithOutLimits = [];
      this.limitsByCurrencies = [];

      try {
        const id = value._id;
        this.gameId = id;
        const currenciesLimits = await this.findLimits({
          path: 'dragon-tiger',
          id,
        });

        if (currenciesLimits.ok) {
          this.updatingLimits = true;
        }

        const data = await this.fetchGameCurrencies({
          path: 'dragon-tiger',
          id,
        });

        if (data.ok) {
          this.dtCurrencies = data.currencies.map(({ currency, ...doc }) => ({
            ...doc,
            ...currency,
          }));

          if (currenciesLimits.ok) {
            const formattedCurrencies = [];
            const shorts = [];
            const currWithOutLimits = [];

            this.dtCurrencies.map((currency) => {
              currenciesLimits.limits.filter((limit) => {
                if (currency.short === limit.short) {
                  shorts.push(currency.short);
                  formattedCurrencies.push({
                    ...currency,
                    ...limit,
                    currency: currency._id,
                  });
                }
              });
            });

            this.dtCurrencies.filter((currency) => {
              if (!shorts.includes(currency.short)) {
                currWithOutLimits.push(currency);
              }
            });

            this.dtCurrencies = formattedCurrencies;
            this.currWithOutLimits = currWithOutLimits;
          }

          this.noLimitsCurrencies = this.currWithOutLimits.map((currency) => {
            return {
              currency: currency.short,
            };
          });
          this.limitsByCurrencies = this.dtCurrencies.map((currency) => {
            return {
              currency: currency.short,
            };
          });
        }

        return (this.$refs.configLimits.modal = true);
      } catch (error) {
        console.log('Error obteniendo monedas de la ruleta', error);
      }
    },
    handleGoBack() {
      this.configChips();
    },
    async handleSelection() {
      const { currency } = this.filterCurrency;
      if (currency) {
        this.filterChip = { currencyId: currency._id };
      }

      this.configChips();
    },
    nextPageChip() {
      this.chipPage++;
      this.handleSelection();
    },
    prevPageChip() {
      this.chipPage--;
      this.handleSelection();
    },
    async handleDeleteSwal(title) {
      const { isConfirmed } = await Swal.fire({
        title,
        showDenyButton: true,
      });

      if (!isConfirmed) return false;

      return true;
    },
    handleAlertSwal(icon, title) {
      Swal.fire({
        position: 'center',
        icon,
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    ...mapActions({
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchGameCurrencies: 'games/fetchGameCurrencies',
      fetchDefaultChips: 'chips/fetchChipsDefault',
      findChips: 'games/findChips',
      findLimits: 'currencies/getLimits',
      fetchGames: 'dragonTiger/fetchGames',
      deleteGame: 'dragonTiger/deleteGame',
    }),
  },
  mounted() {
    // this.handleOpen();
    this.getData();
  },
};
</script>

<style></style>

<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{
        tigerDragon.currencies.length ? 'Modificar monedas' : 'Agregar Monedas'
      }}</template
    >
    <div>
      <div>
        <b-row class="mb-3 mx-auto">
          <multiselect
            v-model="value"
            label="name"
            track-by="name"
            :options="currencies"
            :multiple="true"
          >
          </multiselect>
        </b-row>
        <div class="mb-3 mx-auto">
          <div class="d-flex justify-content-center">
            <vs-button type="submit" @click="save">Guardar</vs-button>
            <vs-button @click="closeModa()" success type="button"
              >Cancelar</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';

import { mapActions } from 'vuex';

export default {
  components: { Multiselect },
  props: {
    tigerDragon: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currencies: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      fichas: [
        { valor: '1' },
        { valor: '2' },
        { valor: '3' },
        { valor: '4' },
        { valor: '5' },
      ],
      value: [],
      selectionOpen: false,
    };
  },

  methods: {
    async save() {
      this.typesubmit = true;

      if (this.tigerDragon.currencies.length) {
        const data = await this.updateCurrencies({
          path: 'dragon-tiger',
          id: this.tigerDragon._id,
          currencies: this.value,
        });

        if (!data.ok) {
          this.closeModa();

          return Swal.fire({
            title: 'Error actualizando las currencies',
            text: data.msg,
            icon: 'error',
          });
        }

        Swal.fire({
          title: 'Monedas actualizadas',
          text: data.msg,
          icon: 'success',
        });
      } else {
        const data = await this.addCurrencies({
          path: 'dragon-tiger',
          id: this.tigerDragon._id,
          currencies: this.value,
        });

        if (!data.ok) {
          this.closeModa();

          return Swal.fire({
            title: 'Error agregando las monedas',
            text: data.msg,
            icon: 'error',
          });
        }
        Swal.fire({
          title: 'Monedas agregadas',
          text: data.msg,
          icon: 'success',
        });
      }
      this.closeModa();
    },
    closeModa() {
      this.modal = false;
    },
    ...mapActions({
      addCurrencies: 'games/addCurrencies',
      updateCurrencies: 'games/updateCurrencies',
    }),
  },
  watch: {
    tigerDragon(newValue) {
      this.value = [];

      const { currencies } = newValue;

      const values = currencies.map(({ currency }) => ({
        _id: currency._id,
        name: currency.name,
      }));
      this.value.push(...values);
    },
  },
};
</script>

<style scoped>
.multipleSelect {
  width: 95%;
  z-index: 2;
  cursor: pointer;
}

.select-area {
  height: 39px;
  overflow-y: scroll;
  cursor: pointer;
}

.select-area::-webkit-scrollbar {
  display: none;
}

.select-area > div {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 4px;
}

.chip {
  display: flex;
  height: 30px;
  font-size: 12px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background-color: #c8c8c8;
  margin-right: 5px;
  padding: 0 5px;
}

.closebtn {
  color: #888;
  font-weight: bolder;
  cursor: pointer;
  width: 10px;
  margin-left: 10px;
  font-size: 14px;
}

.closebtn:hover {
  color: #000;
}
</style>

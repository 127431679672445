<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>Agregar ronda manualmente</template>
    <div class="d-flex">
      <b-tabs pills card v-model="tabIndex" class="w-100">
        <b-tab title="Iniciar ronda">
          <div class="d-flex justify-content-center mt-4">
            <vs-tooltip>
              <vs-button @click="startRound">Iniciar</vs-button>
              <template #tooltip>Presione para iniciar ronda</template>
            </vs-tooltip>
          </div>
        </b-tab>
        <b-tab title="Seleccionar cartas">
          <div v-if="cards.length">
            <div
              v-for="(card, i) in cards"
              :key="i"
              class="card-accepted"
              :style="`color:${card.color}`"
              @click="selectCard(card)"
            >
              <span class="card-left-number">{{ card.name }}</span>
              <span class="card-type">{{ cardTypes[card.type] }}</span>
              <span class="card-right-number">{{ card.name }}</span>
            </div>
          </div>
        </b-tab>
        <b-tab title="Cerrar ronda">
          <div class="d-flex justify-content-center mt-4">
            <vs-tooltip>
              <vs-button
                @click="closeRound"
                :disabled="
                  (Object.keys(result.card1).length &&
                    Object.keys(result.card2).length) < 1
                "
                >Cerrar</vs-button
              >
              <template #tooltip>Presione para cerrar ronda</template>
            </vs-tooltip>
          </div>
        </b-tab>
      </b-tabs>
      <div
        class="w-50 p-2"
        v-if="
          Object.keys(result.card1).length || Object.keys(result.card2).length
        "
      >
        <p class="mb-2">Cartas seleccionadas:</p>
        <div v-for="(card, i) in result" :key="i" class="d-inline-block me-4">
          <div v-if="Object.keys(card).length" class="card-selected">
            <span @click="removeSelection(i)">X</span>
            <div class="card-accepted" :style="`color:${card.color}`">
              <span>{{ card.name }}</span>
              <span>{{ cardTypes[card.type] }}</span>
              <span>{{ card.name }}</span>
            </div>
            <h5>{{ i === 'card1' ? 'Dragon' : 'Tiger' }}</h5>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  props: {
    game: {
      type: Object,
    },
  },
  data() {
    return {
      modal: false,
      cards: [],
      cardTypes: {
        trebol: '♣',
        pica: '♠',
        corazon: '♥',
        diamante: '♦',
      },
      tabIndex: 0,
      result: {
        card1: {},
        card2: {},
      },
      roundUuid: '',
    };
  },
  methods: {
    generateCards() {
      let type = '';
      let color = '';
      for (let pike = 1; pike <= 4; pike++) {
        switch (pike) {
          case 1:
            type = 'pica';
            color = 'black';
            break;
          case 2:
            type = 'corazon';
            color = 'red';
            break;
          case 3:
            type = 'diamante';
            color = 'red';
            break;
          case 4:
            type = 'trebol';
            color = 'black';
            break;
          default:
            break;
        }
        for (let card = 1; card <= 13; card++) {
          let name =
            card === 1
              ? 'A'
              : card === 11
                ? 'J'
                : card === 12
                  ? 'Q'
                  : card === 13
                    ? 'K'
                    : card;
          this.cards.push({ name, type, value: card, color });
        }
      }
    },
    selectCard(card) {
      if (
        Object.keys(this.result.card1).length &&
        Object.keys(this.result.card2).length
      )
        return;

      if (Object.keys(this.result.card1).length) {
        this.result.card2 = card;
      } else {
        this.result.card1 = card;
      }
    },
    removeSelection(key) {
      this.result[key] = {};
    },
    async startRound() {
      this.roundUuid = crypto.randomUUID();

      const dataStartRound = {
        roundId: this.roundUuid,
        providerId: this.game.providerId,
      };
      try {
        const { status } = await this.$httpDragonTiger.put(
          'round/start',
          dataStartRound
        );

        if (status > 201) return Swal.fire('Error creando ronda', '', 'error');

        Swal.fire('Ronda creada', '', 'success');
        this.tabIndex += 1;
      } catch (error) {
        console.log('ERROR START ROUND', error);
      }
    },
    async closeRound() {
      if (!this.roundUuid)
        return Swal.fire('No se ha iniciado la ronda', '', 'info');

      const dataEndRound = {
        roundId: this.roundUuid,
        providerId: this.game.providerId,
        result: this.result,
      };

      try {
        const { status } = await this.$httpDragonTiger.put(
          'round/end',
          dataEndRound
        );

        if (status > 201) return Swal.fire('Error cerrando ronda', '', 'error');

        Swal.fire('Ronda cerrada', '', 'success');
        this.tabIndex = 0;
        this.result = { card1: {}, card2: {} };
      } catch (error) {
        console.log('ERROR START ROUND', error);
      }
    },
  },
  mounted() {
    this.generateCards();
  },
};
</script>

<style scoped>
.card-accepted {
  background-color: rgb(231, 231, 231);
  box-shadow: 0 0 10px rgb(100, 100, 100);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 80px;
  width: 40px;
  font-weight: bolder;
  font-size: 1.2rem;
  margin: 10px;
  padding: 5px;
}

.card-tiger {
  background-color: #a39011;
}

.card-dragon {
  background-color: #4d0d0d;
}

.card-selected {
  display: grid;
}

.card-selected > span {
  justify-self: end;
  background-color: red;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  transform: translateY(100%);
  color: #eee;
  cursor: pointer;
  opacity: 0.6;
}

.card-selected > span:hover {
  opacity: 1;
}
</style>

<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Mesa Fisica</label>
            <div>
              <select
                v-model="typeform.providerId"
                name="providerId"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.providerId.$error,
                }"
                placeholder="Seleccione.."
                ref="rouletteFisicSelect"
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in gameFisic"
                  :key="index"
                  :value="item.providerId"
                  :selected="typeform.providerId == item.providerId"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.providerId.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.providerId.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <vs-input
              v-model="typeform.name"
              placeholder="Nombre"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Logo</label>
            <vs-input
              v-model="typeform.logo"
              placeholder="Logo"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.logo.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.logo.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.logo.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>

        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre Op. 1 (Ej. 'Tigre')</label>
            <vs-input
              v-model="typeform.nameOfTiger"
              placeholder="Tigre"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.nameOfTiger.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.nameOfTiger.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.nameOfTiger.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre Op. 2 (Ej. 'Dragón')</label>
            <vs-input
              v-model="typeform.nameOfDragon"
              placeholder="Dragón"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.nameOfDragon.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.nameOfDragon.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.nameOfDragon.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Chance Simple</label>
            <vs-input
              v-model="typeform.chanceSimple"
              placeholder="2"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.chanceSimple.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.chanceSimple.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.chanceSimple.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Empate</label>
            <vs-input
              v-model="typeform.tie"
              placeholder="12"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.tie.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.tie.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.tie.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Empate perfecto</label>
            <vs-input
              v-model="typeform.perfectTie"
              placeholder="75"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.perfectTie.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.perfectTie.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.perfectTie.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>

        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Jackpot (7 en línea)</label>
            <vs-input
              v-model="typeform.jackpot"
              placeholder="Jackpot (7. nl)"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.jackpot.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.jackpot.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.jackpot.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>K Dorada</label>
            <vs-input
              v-model="typeform.goldenK"
              placeholder="K Dorada"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.goldenK.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.goldenK.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.goldenK.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Color</label>
            <vs-input
              v-model="typeform.color"
              placeholder="Color"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.color.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.color.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.color.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Tamaño</label>
            <vs-input
              v-model="typeform.size"
              placeholder="Size"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.size.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.size.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.size.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Paridad</label>
            <vs-input
              v-model="typeform.parity"
              placeholder="Parity"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.parity.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.parity.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.parity.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Duracion de ronda</label>
            <vs-input
              v-model="typeform.roundDuration"
              placeholder="En segundos"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.roundDuration.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.roundDuration.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.roundDuration.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Max. Ronda para apuestas secundarias</label>
            <vs-input
              v-model="typeform.maxRoundsSecBets"
              placeholder="Ej: 50"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.maxRoundsSecBets.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.maxRoundsSecBets.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.maxRoundsSecBets.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Imagen de fondo</label>
            <vs-input
              v-model="typeform.imgBackground"
              placeholder="url background"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.imgBackground.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.imgBackground.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.imgBackground.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Color de fondo</label>
            <vs-input
              v-model="typeform.colorBackground"
              placeholder="color background"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.colorBackground.$error,
              }"
              type="color"
            />
            <div
              v-if="typesubmit && $v.typeform.colorBackground.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.colorBackground.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL de transmision</label>
            <vs-input
              v-model="typeform.urlTransmision"
              placeholder="color background"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.urlTransmision.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.urlTransmision.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.urlTransmision.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Delay para el resultado</label>
            <vs-input
              v-model="typeform.delay"
              placeholder="3"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.delay.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.delay.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.delay.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <vs-checkbox v-model="typeform.isAutomaticDeck"
              >Maso automatico</vs-checkbox
            >
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    gameFisic: {
      type: Array,
      default: () => {
        return [
          {
            name: 'EMULATOR TG',
            providerId: 'm1',
          },
          {
            name: 'X',
            providerId: 'X',
          },
        ];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      selectionOpen: false,
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      nameOfDragon: {
        required,
      },
      nameOfTiger: {
        required,
      },
      chanceSimple: {
        required,
      },
      tie: {
        required,
      },
      perfectTie: {
        required,
      },
      jackpot: {
        required,
      },
      goldenK: {
        required,
      },
      color: {
        required,
      },
      size: {
        required,
      },
      parity: {
        required,
      },
      providerId: {
        required,
      },
      logo: {
        required,
      },
      imgBackground: {
        required,
      },
      colorBackground: {
        required,
      },
      roundDuration: {
        required,
      },
      urlTransmision: {
        required,
      },
      maxRoundsSecBets: {
        required,
        numeric,
      },
      delay: {
        required,
        numeric,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'dragonTiger/isSuccess',
    }),
  },
  methods: {
    save() {
      this.typesubmit = true;
      // stop here if form is invalid
      console.log(this.typeform);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('error');
      } else {
        if (this.typeform._id) {
          this.updateDragonTiger();
        } else {
          this.createDragontTiger();
        }
      }
    },
    async createDragontTiger() {
      try {
        await this.create(this.typeform);

        if (!this.success)
          return this.handleAlertSwal('error', 'ERROR CREANDO DRAGON TIGER');

        this.handleAlertSwal('success', 'DRAGON TIGER CREADO');
        this.$emit('closeModa');
      } catch (error) {
        console.log(error);
      }
    },
    async updateDragonTiger() {
      try {
        const { uuid: id, ...dataToUpdate } = this.typeform;

        await this.update({ id, dataToUpdate });

        if (!this.success)
          return this.handleAlertSwal('error', 'Error actualizando el juego');

        this.handleAlertSwal('success', 'Juego guardado con exito');
        this.$emit('closeModa');
      } catch (error) {
        console.log(error);
      }
    },
    handleSelection(currency) {
      const currencies = this.typeform.currencies.filter(
        (curr) => currency._id === curr._id
      );

      if (currencies.length) return;

      this.typeform.currencies.push(currency);
    },
    deleteSelection(id) {
      const updateSelection = this.typeform.currencies.filter(
        (currency) => currency._id !== id
      );

      this.typeform.currencies = updateSelection;
    },
    handleOpen() {
      this.selectionOpen = !this.selectionOpen;
    },
    handleChange() {
      const rltSelected = this.rouletteFisics.find(
        (rlt) => rlt.providerId === this.typeform.providerId
      );

      if (!rltSelected) return;

      this.showSpecialCalle = rltSelected.doubleZero;
    },
    async handleDeleteSwal(title) {
      const { isConfirmed } = await Swal.fire({
        title,
        showDenyButton: true,
      });

      if (!isConfirmed) return false;

      return true;
    },
    handleAlertSwal(icon, title) {
      Swal.fire({
        position: 'center',
        icon,
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    ...mapActions({
      create: 'dragonTiger/createGame',
      update: 'dragonTiger/updateGame',
    }),
  },
};
</script>

<style scoped>
.multipleSelect {
  width: 95%;
  z-index: 2;
  cursor: pointer;
}

.select-area {
  height: 39px;
  overflow-y: scroll;
  cursor: pointer;
}

.select-area::-webkit-scrollbar {
  display: none;
}

.select-area > div {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 4px;
}

.chip {
  display: flex;
  height: 30px;
  font-size: 12px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  background-color: #c8c8c8;
  margin-right: 5px;
  padding: 0 5px;
}

.closebtn {
  color: #888;
  font-weight: bolder;
  cursor: pointer;
  width: 10px;
  margin-left: 10px;
  font-size: 14px;
}

.closebtn:hover {
  color: #000;
}
</style>

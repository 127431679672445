var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Mesa Fisica")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.providerId),expression:"typeform.providerId"}],ref:"rouletteFisicSelect",staticClass:"form-select border-0 shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.providerId.$error,
              },attrs:{"name":"providerId","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "providerId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.gameFisic),function(item,index){return _c('option',{key:index,domProps:{"value":item.providerId,"selected":_vm.typeform.providerId == item.providerId}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.providerId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.providerId.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
            },attrs:{"placeholder":"Nombre","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Logo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.logo.$error,
            },attrs:{"placeholder":"Logo","border":""},model:{value:(_vm.typeform.logo),callback:function ($$v) {_vm.$set(_vm.typeform, "logo", $$v)},expression:"typeform.logo"}}),(_vm.typesubmit && _vm.$v.typeform.logo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.logo.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre Op. 1 (Ej. 'Tigre')")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.nameOfTiger.$error,
            },attrs:{"placeholder":"Tigre","border":""},model:{value:(_vm.typeform.nameOfTiger),callback:function ($$v) {_vm.$set(_vm.typeform, "nameOfTiger", $$v)},expression:"typeform.nameOfTiger"}}),(_vm.typesubmit && _vm.$v.typeform.nameOfTiger.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.nameOfTiger.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre Op. 2 (Ej. 'Dragón')")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.nameOfDragon.$error,
            },attrs:{"placeholder":"Dragón","border":""},model:{value:(_vm.typeform.nameOfDragon),callback:function ($$v) {_vm.$set(_vm.typeform, "nameOfDragon", $$v)},expression:"typeform.nameOfDragon"}}),(_vm.typesubmit && _vm.$v.typeform.nameOfDragon.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.nameOfDragon.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Chance Simple")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.chanceSimple.$error,
            },attrs:{"placeholder":"2","border":""},model:{value:(_vm.typeform.chanceSimple),callback:function ($$v) {_vm.$set(_vm.typeform, "chanceSimple", $$v)},expression:"typeform.chanceSimple"}}),(_vm.typesubmit && _vm.$v.typeform.chanceSimple.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.chanceSimple.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Empate")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.tie.$error,
            },attrs:{"placeholder":"12","border":""},model:{value:(_vm.typeform.tie),callback:function ($$v) {_vm.$set(_vm.typeform, "tie", $$v)},expression:"typeform.tie"}}),(_vm.typesubmit && _vm.$v.typeform.tie.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.tie.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Empate perfecto")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.perfectTie.$error,
            },attrs:{"placeholder":"75","border":""},model:{value:(_vm.typeform.perfectTie),callback:function ($$v) {_vm.$set(_vm.typeform, "perfectTie", $$v)},expression:"typeform.perfectTie"}}),(_vm.typesubmit && _vm.$v.typeform.perfectTie.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.perfectTie.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Jackpot (7 en línea)")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.jackpot.$error,
            },attrs:{"placeholder":"Jackpot (7. nl)","border":""},model:{value:(_vm.typeform.jackpot),callback:function ($$v) {_vm.$set(_vm.typeform, "jackpot", $$v)},expression:"typeform.jackpot"}}),(_vm.typesubmit && _vm.$v.typeform.jackpot.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.jackpot.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("K Dorada")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.goldenK.$error,
            },attrs:{"placeholder":"K Dorada","border":""},model:{value:(_vm.typeform.goldenK),callback:function ($$v) {_vm.$set(_vm.typeform, "goldenK", $$v)},expression:"typeform.goldenK"}}),(_vm.typesubmit && _vm.$v.typeform.goldenK.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.goldenK.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Color")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.color.$error,
            },attrs:{"placeholder":"Color","border":""},model:{value:(_vm.typeform.color),callback:function ($$v) {_vm.$set(_vm.typeform, "color", $$v)},expression:"typeform.color"}}),(_vm.typesubmit && _vm.$v.typeform.color.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.color.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tamaño")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.size.$error,
            },attrs:{"placeholder":"Size","border":""},model:{value:(_vm.typeform.size),callback:function ($$v) {_vm.$set(_vm.typeform, "size", $$v)},expression:"typeform.size"}}),(_vm.typesubmit && _vm.$v.typeform.size.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.size.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Paridad")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.parity.$error,
            },attrs:{"placeholder":"Parity","border":""},model:{value:(_vm.typeform.parity),callback:function ($$v) {_vm.$set(_vm.typeform, "parity", $$v)},expression:"typeform.parity"}}),(_vm.typesubmit && _vm.$v.typeform.parity.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.parity.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Duracion de ronda")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.roundDuration.$error,
            },attrs:{"placeholder":"En segundos","border":""},model:{value:(_vm.typeform.roundDuration),callback:function ($$v) {_vm.$set(_vm.typeform, "roundDuration", $$v)},expression:"typeform.roundDuration"}}),(_vm.typesubmit && _vm.$v.typeform.roundDuration.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.roundDuration.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Max. Ronda para apuestas secundarias")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.maxRoundsSecBets.$error,
            },attrs:{"placeholder":"Ej: 50","border":""},model:{value:(_vm.typeform.maxRoundsSecBets),callback:function ($$v) {_vm.$set(_vm.typeform, "maxRoundsSecBets", $$v)},expression:"typeform.maxRoundsSecBets"}}),(_vm.typesubmit && _vm.$v.typeform.maxRoundsSecBets.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.maxRoundsSecBets.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Imagen de fondo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.imgBackground.$error,
            },attrs:{"placeholder":"url background","border":""},model:{value:(_vm.typeform.imgBackground),callback:function ($$v) {_vm.$set(_vm.typeform, "imgBackground", $$v)},expression:"typeform.imgBackground"}}),(_vm.typesubmit && _vm.$v.typeform.imgBackground.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.imgBackground.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Color de fondo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.colorBackground.$error,
            },attrs:{"placeholder":"color background","border":"","type":"color"},model:{value:(_vm.typeform.colorBackground),callback:function ($$v) {_vm.$set(_vm.typeform, "colorBackground", $$v)},expression:"typeform.colorBackground"}}),(_vm.typesubmit && _vm.$v.typeform.colorBackground.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.colorBackground.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL de transmision")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.urlTransmision.$error,
            },attrs:{"placeholder":"color background","border":""},model:{value:(_vm.typeform.urlTransmision),callback:function ($$v) {_vm.$set(_vm.typeform, "urlTransmision", $$v)},expression:"typeform.urlTransmision"}}),(_vm.typesubmit && _vm.$v.typeform.urlTransmision.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.urlTransmision.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Delay para el resultado")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.delay.$error,
            },attrs:{"placeholder":"3","border":""},model:{value:(_vm.typeform.delay),callback:function ($$v) {_vm.$set(_vm.typeform, "delay", $$v)},expression:"typeform.delay"}}),(_vm.typesubmit && _vm.$v.typeform.delay.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.delay.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('vs-checkbox',{model:{value:(_vm.typeform.isAutomaticDeck),callback:function ($$v) {_vm.$set(_vm.typeform, "isAutomaticDeck", $$v)},expression:"typeform.isAutomaticDeck"}},[_vm._v("Maso automatico")])],1)])],1),_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',[_vm._v("Guardar")]),_c('vs-button',{attrs:{"success":"","type":"button"},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" Cancelar ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }